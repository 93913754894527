/* Mixins */
.report-chart-component .jtl-table-query-component {
  width: calc(100% - 76px);
  display: inline-block;
}
.report-chart-component .report-chart-query-search {
  float: right;
}
.report-chart-component .report-content .ant-card-body {
  width: 100%;
  min-height: 524px;
}